import { admin2 } from './instance'

// GET admin.rewardDistribution.find
export const GetRewardDistribution = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution`,
    method: 'GET',
    params: {
      name,
      start,
      limit,
    },
  })
}

// GET admin.rewardDistribution.count
export const GetRewardDistributionCount = async ({
  shopId,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution/count`,
    method: 'GET',
  })
}

// GET admin.rewardDistribution.findOne
export const FindRewardDistribution = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution/${id}`,
    method: 'GET',
  })
}

// POST admin.rewardDistribution.create
export const CreateRewardDistribution = async ({
  shopId,
  name,
  status,
  startAt,
  endAt,
  description,
  notice,
  awardName,
  availableStock,
  unlimitedStock,
  showAvailableStock,
  requirements,
  awardType,
  awardAmount,
  awardId,
  rewardDistributionImageId,
  awardImageId,
  shopPointKey,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution`,
    method: 'POST',
    data: {
      name,
      status,
      startAt,
      endAt,
      description,
      notice,
      awardName,
      availableStock,
      unlimitedStock,
      showAvailableStock,
      requirements,
      awardType,
      awardAmount,
      awardId,
      rewardDistributionImageId,
      awardImageId,
      shopPointKey,
    },
  })
}

// PUT admin.rewardDistribution.update
export const UpdateRewardDistribution = async ({
  shopId,
  id,
  startAt,
  endAt,
  description,
  notice,
  rewardDistributionImageId,
  awardImageId,
  availableStock,
  unlimitedStock,
  showAvailableStock,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution/${id}`,
    method: 'PUT',
    data: {
      startAt,
      endAt,
      description,
      notice,
      rewardDistributionImageId,
      awardImageId,
      availableStock,
      unlimitedStock,
      showAvailableStock,
    },
  })
}

// DELETＥadmin.rewardDistribution.destroy
export const DeleteRewardDistribution = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution/${id}`,
    method: 'DELETE',
  })
}

// PUT admin.rewardDistribution.updateAward
export const UpdateRewardDistributionAward = async ({
  shopId,
  id,
  showAvailableStock,
  amount,
  awardId,
  awardImageId,
  unlimitedStock,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistribution/${id}/award`,
    method: 'PUT',
    data: {
      showAvailableStock,
      amount,
      awardId,
      awardImageId,
      unlimitedStock,
    },
  })
}

// ---------------- //

// GET admin.rewardDistributionLinkUseRecord.find
export const GetRewardDistributionLinkUseRecord = async ({
  shopId,
  MemberId,
  RewardDistributionId,
  createdAtStart,
  createdAtEnd,
  start,
  limit,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistributionLinkUseRecord`,
    method: 'GET',
    params: {
      MemberId,
      RewardDistributionId,
      createdAtStart,
      createdAtEnd,
      start,
      limit,
    },
  })
}

// GET admin.rewardDistributionLinkUseRecord.count
export const GetRewardDistributionLinkUseRecordCount = async ({
  shopId,
  MemberId,
  RewardDistributionId,
  createdAtStart,
  createdAtEnd,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistributionLinkUseRecord/count`,
    method: 'GET',
    params: {
      MemberId,
      RewardDistributionId,
      createdAtStart,
      createdAtEnd,
    },
  })
}

// GET admin.rewardDistributionLinkUseRecord.statistical
export const GetRewardDistributionLinkUseRecordStatistical = async ({
  shopId,
  RewardDistributionId,
  createdAtStart,
  createdAtEnd,
}) => {
  return await admin2({
    url: `/${shopId}/rewardDistributionLinkUseRecord/statistical`,
    method: 'GET',
    params: {
      RewardDistributionId,
      createdAtStart,
      createdAtEnd,
    },
  })
}
